const WEBGL = require('./WebGL');


module.exports = function(loader) {

  function addParticles() {

    if (WEBGL.isWebGLAvailable() === false) {
      document.body.appendChild(WEBGL.getWebGLErrorMessage());
    }


    var geometry = new THREE.BufferGeometry();
    var vertices = [];
    var materials = [],
      parameters;
    var sprite1 = loader.load('assets/images/textures/sprites/snowflake4.png');
    var sprite2 = loader.load('assets/images/textures/sprites/snowflake4.png');
    var sprite3 = loader.load('assets/images/textures/sprites/snowflake4.png');
    var sprite4 = loader.load('assets/images/textures/sprites/snowflake4.png');
    var sprite5 = loader.load('assets/images/textures/sprites/snowflake4.png');

    for (var i = 0; i < 500; i++) {
      var x = Math.random() * 2000 - 1000;
      var y = Math.random() * 2000 - 1000;
      var z = Math.random() * 2000 - 1000;
      vertices.push(x, y, z);
    }

    geometry.addAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    parameters = [
      [
        [1.0, 0.2, 0.5], sprite2, 5
      ],
      [
        [0.95, 0.1, 0.5], sprite3, 5
      ],
      [
        [0.90, 0.05, 0.5], sprite1, 5
      ],
      [
        [0.85, 0, 0.5], sprite5, 5
      ],
      [
        [0.80, 0, 0.5], sprite4, 5
      ]
    ];

    for (var i = 0; i < parameters.length; i++) {
      var color = parameters[i][0];
      var sprite = parameters[i][1];
      var size = parameters[i][2];
      materials[i] = new THREE.PointsMaterial({
        size: size,
        map: sprite,
        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: true
      });
      materials[i].color.setHSL(color[0], color[1], color[2]);
      var particles = new THREE.Points(geometry, materials[i]);
      particles.rotation.x = Math.random() * 6;
      particles.rotation.y = Math.random() * 6;
      particles.rotation.z = Math.random() * 6;
      scene.add(particles);
    }
  }

  function renderParticles() {
    var time = Date.now() * 0.00001;

    for (var i = 0; i < scene.children.length; i++) {
      var object = scene.children[i];
      if (object instanceof THREE.Points) {
        object.rotation.y = time * (i < 4 ? i + 1 : -(i + 1));
      }
    }

  }


  const scene_particles = {
    addParticles: () => {
      addParticles();
    },
    renderParticles: () => {
      renderParticles();
    }
  }

  return scene_particles;
};