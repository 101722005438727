const DeviceOrientationControls = require('./DeviceOrientationControls');
const OrbitControls = require('./OrbitControls');
const PointerLockControls = require('./PointerLockControls');

let raycaster;
let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;
let canJump = false;
let prevTime = performance.now();
let velocity = new THREE.Vector3();
let direction = new THREE.Vector3();
let vertex = new THREE.Vector3();
let color = new THREE.Color();

function controlsSetupMobile() {
  camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1100);
  renderer = new THREE.WebGLRenderer({
    antialias: true
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.body.appendChild(renderer.domElement);
  controls = new THREE.DeviceOrientationControls(camera);
}

function controlsSetupDesktop() {
  camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1100);
  camera.position.z = 0.01;

  camera.enableZoom = true;
  renderer = new THREE.WebGLRenderer({
    antialias: true
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.body.appendChild(renderer.domElement);
  var controls = new THREE.OrbitControls(camera, renderer.domElement);
  controls.enablePan = false;
  
  window.addEventListener( 'resize', onWindowResize, false );

  function onWindowResize(){

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      
      renderer.setSize( window.innerWidth, window.innerHeight );

  }
}

function controlSetupDesktopPointerLock(){

 
 

  var blocker = document.getElementById('loadingScreen');




  // raycaster = new THREE.Raycaster( new THREE.Vector3(), new THREE.Vector3( 0, - 1, 0 ), 0, 0 );
  
  var container = document.getElementById("canvas");
  renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
  renderer.setSize($(container).width(), $(window).height() );
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setClearColor(0xededed, 0)
  container.appendChild(renderer.domElement);
  camera = new THREE.PerspectiveCamera( 75, $(container).width() / $(container).height(), 1, 1100 );
  controls = new THREE.PointerLockControls( camera );
  scene.add( controls.getObject() );
  controls.getObject().position.y = 0;
  camera.enableZoom = true;


  blocker.addEventListener( 'click', function () {
    controls.lock();
  }, false );

  var audio = document.getElementById('pageAudio');
  controls.addEventListener( 'lock', function () {

    $('body').addClass('experience-active').removeClass('lock-screen');
    $('#backgroundWrapper').hide(0);
    $('#backgroundFades').hide(0);
    audio.play();


  } );

  controls.addEventListener( 'unlock', function () {

    $('body').removeClass('experience-active lock-screen').addClass('lock-screen');
    $('#backgroundWrapper').show(0);
    $('#backgroundFades').show(0);
    audio.pause();

  } );


}


const scene__init = {
  init: () => {
    init();
    animate();
  }
}

const scene_controls = {
  controlsSetupMobile: () => {
      controlsSetupMobile();
  },
  controlsSetupDesktop: () => {
      controlsSetupDesktop();
  },
  controlSetupDesktopPointerLock: () => {
      controlSetupDesktopPointerLock();
  }
}

module.exports = scene_controls;