// Packages
const $ = require('jquery');
window.$ = window.JQuery =  $;
window.THREE = require('three');


const Scene = require('./modules/scene');
const Oscillioscope = require('./modules/Oscilloscope');


let init = () => { 


	Scene.init();
	Oscillioscope.init();

}; 


$(document).ready(function() {  
	init();
});