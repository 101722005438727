const TWEEN = require('tween.js');
const manager = new THREE.LoadingManager();
const loader = new THREE.TextureLoader(manager);

const sceneControls = require('./_scene_controls');
const sceneParticles = require('./_scene_particles')(loader);
const raycaster = new THREE.Raycaster();
let INTERSECTED;

const intersectObjectsCheck = [];

let wombImages = [];
let wombImagesMesh = [];
let imageCount = 0;

let isZoom = false;

manager.onStart = function(url, itemsLoaded, itemsTotal) {
  
};

manager.onLoad = function() {
  $('body').addClass('loaded');
  setTimeout(function() {
    $('.loading-bar').fadeOut(250, function(){
      $('#loadingText').css('opacity', '0');
      setTimeout(function() {
         $('#loadingText').text('TAP to Enter').css('opacity', '1').addClass('blink');
      }, 500);
    });
  }, 500);
};

manager.onProgress = function(url, itemsLoaded, itemsTotal) {
  let percentageLoaded = 100/itemsTotal * itemsLoaded;
  $('.loading-bar').css('width', percentageLoaded + '%');
  
};

manager.onError = function(url) {
  console.log('There was an error loading ' + url);
};



function add_Womb(texture) {
  const geometry = new THREE.SphereBufferGeometry(500, 60, 40);
  // invert the geometry on the x-axis so that all of the faces point inward
  geometry.scale(-1, 1, 1);

  const material = new THREE.MeshBasicMaterial({
    map: new THREE.TextureLoader().load(texture)
  });
  const mesh = new THREE.Mesh(geometry, material);
  scene.add(mesh);
  const helperGeometry = new THREE.BoxBufferGeometry(100, 100, 100, 4, 4, 4);
  const helperMaterial = new THREE.MeshBasicMaterial({
    color: 0xff00ff,
    wireframe: false
  });
  const helper = new THREE.Mesh(helperGeometry, helperMaterial);
  scene.add(helper);
}

function randomIntFromInterval(min,max) {
    return Math.floor(Math.random()*(max-min+1)+min);
}

function loadImage(loader, images, i, radius) {

  loader.load(images[i][0], function(texture) {

    var imagePosition = images[i][2];


    const material = new THREE.MeshBasicMaterial({
      map: texture
    });
    var geometry = new THREE.PlaneBufferGeometry((15 * images[i][1]), 15);
    var mesh = new THREE.Mesh(geometry, material);


    const meshObject = {
      mesh: mesh,
      meshCount: images[i][2],
      imageText: images[i][3],
      hitCount: 0
    }

    wombImages.push(meshObject);

    if (wombImages.length == images.length) {
      wombImages.sort(function(a, b) {
        return a.meshCount - b.meshCount;
      });

      // console.log(wombImages); 
      for (var index = wombImages.length - 1; index >= 0; index--) {
        // console.log(wombImages[index]);
        var phi = Math.acos(index / images.length);
        var theta = ((Math.PI / wombImages.length) * index) + (Math.PI / wombImages.length)*0.5;
        // var theta = (Math.PI / wombImages.length) * index;
        if (index %2 == 0) {
          wombImages[index].mesh.position.setFromSphericalCoords(radius, (randomIntFromInterval(30,50)/100)  * Math.PI, (theta *-2 ));
        } else {
          wombImages[index].mesh.position.setFromSphericalCoords(radius, (randomIntFromInterval(50,70)/100)  * Math.PI, (theta *-2));
        }
          
        // wombImages[index].mesh.position.setFromSphericalCoords(radius/2, 0.5 * Math.PI, (-theta*2.5)); // 1.5 Magic
        // wombImages[index].mesh.position.setFromSphericalCoords(radius/2, 0.5 * Math.PI, theta*-2); // 1.5 Magic
        wombImages[index].mesh.lookAt(camera.position);
        scene.add(wombImages[index].mesh);

        wombImagesMesh.push(wombImages[index].mesh);
      }

    }

  }, undefined, function(err) {
    console.error('An error happened.');
  });

}

function add_Images(images) {

  var radius = 72;
  for (var i = images.length - 1; i >= 0; i--) {
    loadImage(loader, images, i, radius);
  }
}

function countIncrease(count, max) {
  if (imageCount < max - 1) {
    imageCount++;
  }
}

function init() {
  
  scene = new THREE.Scene();

  if (isMobile.any) {
    sceneControls.controlsSetupMobile();
  } else {
    // sceneControls.controlsSetupDesktop();
    sceneControls.controlSetupDesktopPointerLock();
  }
 

 

  const api = document.getElementById('Page').getAttribute('data-api');
  fetch(api)
    .then(function(response) {
      return response.json();
    })
    .then(function(json) {
      add_Womb(json[0].skyBoxURL);
      add_Images(json[0].images);
      sceneParticles.addParticles();
    });



  window.addEventListener('resize', onWindowResize, false);


  var zoomStart = {
    zoom: 1
  };
  var zoomEnd = {
    zoom: 10
  };

  var zoomIn = new TWEEN.Tween({
      zoom: 1
    })
    .to({
      zoom: 10
    }, 250)
    .onUpdate(function() {
      camera.zoom = this.zoom;
     
      camera.updateProjectionMatrix();
    });

  var zoomOut = new TWEEN.Tween({
      zoom: 10
    })
    .to({
      zoom: 1
    }, 250)
    .onUpdate(function() {
      camera.zoom = this.zoom;
      // console.log('zoomOut: ' + this.zoom)
      camera.updateProjectionMatrix();
    });

  var onTouchStart = (event) => {
    event.preventDefault();
    camera.zoom = 10;
    isZoom = true;
    $('body').addClass('is_zoom');
    camera.updateProjectionMatrix();
  }

  var onTouchEnd = (event) => {
    event.preventDefault();
    camera.zoom = 1;
    isZoom = false;
    $('body').removeClass('is_zoom');
    $('#viewportDialogue').removeClass('show');
    camera.updateProjectionMatrix();
  }


  var onMouseDown = function( event ) {
    event.stopPropagation();
    camera.zoom = 10;
    isZoom = true;
    $('body').addClass('is_zoom');
    camera.updateProjectionMatrix();
  }

  var onMouseUp = function( event ) {
    event.stopPropagation();
    camera.zoom = 1;
    isZoom = false;
    $('body').removeClass('is_zoom');
    $('#viewportDialogue').removeClass('show');
    camera.updateProjectionMatrix();
  }

  $('canvas').on( 'touchstart', onTouchStart );
  $('canvas').on( 'touchend', onTouchEnd );

  document.addEventListener( 'mousedown', onMouseDown, false );
  document.addEventListener( 'mouseup', onMouseUp, false );


}

function getText(uuid){
  for (var i = 0; i < wombImages.length; i++) {
    if (uuid == wombImages[i].mesh.uuid) {
      $('#viewportDialogueText').text(wombImages[i].imageText);
    }
  }
}

function getObjectIndex(uuid){

  for (var i = 0; i < wombImages.length; i++) {
    if (uuid == wombImages[i].mesh.uuid) {
     return i; 
    }
  }
}

let hitCount = 0; 
let uuid;
let objectIndex;
function animate(time) {
  TWEEN.update(time);
  window.requestAnimationFrame(animate);
  raycaster.setFromCamera( new THREE.Vector2(), camera );
  if (wombImagesMesh.length > 1 && isZoom) {
    var intersects = raycaster.intersectObjects( wombImagesMesh );
        if ( intersects.length > 0 ) {
          if ( INTERSECTED != intersects[ 0 ].object ) {
            let intersect = intersects[ 0 ].object;
            if (uuid != intersect.uuid) {
              objectIndex = getObjectIndex(intersect.uuid);
              $('#viewportDialogueText').text(wombImages[objectIndex].imageText);
              uuid = intersect.uuid;
            }
            $('#viewportDialogue').addClass('show');
            if (!intersectObjectsCheck.includes(intersect)) {
              wombImages[objectIndex].hitCount++;
              var $this = $('#checks .checkMark').eq(objectIndex);
              // console.log($('#checks .checkMark').length, objectIndex);
              var backgroundcolor = $this.data('background');
               $this.css({
                'background': 'rgba('+ backgroundcolor+', '+(wombImages[objectIndex].hitCount*2)/100+')',
                'border-color': 'rgb('+backgroundcolor+')',
                'border-radius': '0px',
                'transform': 'scale(1.33)',
                'box-shadow': '0 0 10px rgba(0,0,0,0.25)'
              });
                $('#viewportDialoguePercentage').text( Math.min(wombImages[objectIndex].hitCount*2, 100) + '%').show(0);
              if (wombImages[objectIndex].hitCount > 50) {
                 $this.css({
                  'background': 'rgb('+ backgroundcolor+')',
                  'border-color': 'white',
                  'transform': 'scale(1)',
                  'box-shadow': 'none',
                  'border-radius': '3px',
                });
                $('#viewportDialoguePercentage').delay(500).fadeOut(500);
                 $('#backgroundWrapper').css({
                  'background': 'rgb('+ backgroundcolor +')'
                 }).show(0).delay(500).fadeOut(1000);
                intersectObjectsCheck.push(intersect);
                $('#trackInfoCount').text(intersectObjectsCheck.length);
                if (intersectObjectsCheck.length == wombImages.length) {
                  $('#checks').addClass('complete');
                  $('body').addClass('experience-complete');
                  $('#siteFooterInfo').fadeOut(500, function(){
                    $(this).html('Photography by Dexter Lander<br>Designed by Alex McCullough and Joseph Pleass')
                    $(this).fadeIn(500);
                  })
                  $(document).on('keydown', function(event) {
                    if (event.keyCode === 13) {
                      console.log('Press');
                      $('#preorderLink').click();
                      window.open('https://whities.bandcamp.com/album/loom-dream', '_blank');
                      return false;
                    }
                  });
                }
              }
            }
          }
        } else {
          INTERSECTED = null;
          $('#viewportDialogue').removeClass('show');

        }
  }

  if (isMobile.any) {
    controls.update();
  }
  sceneParticles.renderParticles();
  renderer.render(scene, camera);

}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

const scene__init = {
  init: () => {
    init();
    animate();
  }
}

module.exports = scene__init;