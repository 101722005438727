const Swiper = require('swiper');

function init(){

	var audio = document.getElementById('pageAudio');
	

	$('#loadingScreen').on('click', function(event) { 
		if ($('body').hasClass('loaded') && !$('body').hasClass('experience-active')) {
			audio.muted = false;
			audio.play();
			$('body').addClass('experience-active');
			$('#backgroundWrapper, #backgroundFades').fadeOut( 500 );
			// $('#waveform, #siteFooter').delay(500).fadeIn(500);
			
			$('#checks__wrapper').delay(0).fadeIn( 250, function(){
				var i = 0;
				var loop = window.setInterval(() => {
					$('.checkMark').eq(i).css('opacity', 1);
					i++;
					if (i == $('.checkMark').length ) {
						clearInterval(loop);
						$('#trackPlayUI, #trackInfo').delay(500).fadeIn(500);
					}
				}, 250)
				
			} );
		}
	});


	$('#waveform').on('click', function(event) {
		
		if ($(this).hasClass('paused')) {
			audio.play();
			$('#siteFooterInfo span').text('Tap to pause');
			$(this).removeClass('paused');
		} else {
			audio.pause();
			$('#siteFooterInfo span').text('Tap to play');
			$(this).addClass('paused');
		}
	});

	audio.addEventListener('timeupdate', function(event) {
		let percentage = (100/audio.duration) * audio.currentTime;
		const paddingAccounting = (100/$(window).width())*15;
		$('#seekLine').css('width', percentage +'%')
	});

} 

 
const oScope__init = {
  init: () => {
    init();
  }
}

module.exports = oScope__init;